import { Button } from "./button";
import { Heading } from "./heading";

export const components = {
  Button,
  Heading,
  FormHelperText: { // this is  not working
    baseStyle: {
      fontSize: "sm",
      color: "gray.500",
      lineHeight: "normal",
    },
  },
  FormLabel: {
    baseStyle: {
      fontWeight: "bold"
    }
  },
};
