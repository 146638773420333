import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "8",
  },
  variants: {
    outline: {
      borderWidth: "3px",
    },
  },
};
