export const styles = {
  global: {
    "body": {
      color: '#212427'
    },
    ":root": {
      // "font-family": "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
      "lineHeight": "1.5",
      "fontHeight": "400"
    },
    "html, body, #root": {
      height: "100%",
      margin: "0"
    },
    ".dndflow": {
      "flexDirection": "column",
      display: "flex",
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow aside": {
      "borderRight": "1px solid #eee",
      padding: "15px 10px",
      "fontSize": "12px",
      background: "#fcfcfc"
    },
    "a": {
      'textDecoration': "none !important"
    },
    ".is-active": {
      background: "#f9f9f9"
    },
    ".dndflow aside .description": {
      "marginBottom": "10px"
    },
    ".dndflow .reactflow-wrapper": {
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow .selectall": {
      "marginTop": "10px"
    },
    "@media screen and (min-width: 768px)": {
      ".dndflow": {
        "flexDirection": "row"
      },
      ".dndflow aside": {
        width: "20%",
        "maxWidth": "250px"
      }
    },
    ".default-box": {
      border: "1px solid black",
      "boxShadow": "0 0 5px rgba(0, 0, 0, 0.2)"
    },
    ".run-connected": {
      color: "white",
      "fontWeight": "900"
    },
    ".run-connected::before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      "borderRadius": "inherit",
      background: "linear-gradient(45deg, #ff7f7f, #d1ffbd, #d1ffbd)",
      "backgroundSize": "400%",
      animation: "glow 3s infinite linear",
      "zIndex": "-1"
    },
    ".edge-hovered": {
      color: "#ff7f7f",
      "backgroundColor": "#ff7f7f",
      "strokeWidth": "4px"
    },
    ".rjsf": {
      width: "100%"
    },
    ".react-flow__node-annotation": {
      "font-size": '16px',
      width: "200px",
      color: "#212427",
      "font-family": "monospace",
      position: "absolute",
    },
    ".react-flow__node-annotation .arrow": {
      position: "absolute",
      "font-size": "24px"
    }
  }
};
