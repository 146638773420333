import type { DeepPartial, Theme } from "@chakra-ui/react";

const brand100 = "#DCDEF6";
/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme["colors"]["blackAlpha"]>
> = {
  brand: {
    50: "#EDEEFB",
    100: brand100,
    200: "#B9BDED",
    300: "#959BE4",
    400: "#727ADB",
    500: "#4F59D2",
    600: "#3F47A8",
    700: "#2F357E",
    800: "#202454",
    900: "#10122A",
  },
  brandSecondary: {
    50: "#FDEFF6",
    100: "#FAD0E4",
    200: "#F7B1D2",
    300: "#F491BF",
    400: "#F172AD",
    500: "#EF62A4",
    600: "#D75894", // Transition point (mid-tone)
    700: "#BF4E83",
    800: "#8F3B62",
    900: "#602742",
  },
  brandTernary: {
    50: "#FADDA2",
    100: "#F9D48B",
    200: "#FFF071",
    300: "#F5BA45",
    400: "#F4B22D",
    500: "#F3A916",
    600: "#C28712", // Transition point (mid-tone)
    700: "#92650D",
    800: "#7A550B",
    900: "#614409",
  },
  brandQuaternary: {
    50: "#E1F8FD",
    100: "#C3F1FB",
    200: "#A5EBFA",
    300: "#96E7F9",
    400: "#78E0F7",
    500: "#69DDF6",
    600: "#5FC7DD",
    700: "#54B1C5",
    800: "#4A9BAC",
    900: "#356F7B",
  },
  facebook: {
    400: "#3b5998",
  },
};

/** override chakra colors here */
const overridenChakraColors: DeepPartial<Theme["colors"]> = {};

export const colors = {
  ...overridenChakraColors,
  ...extendedColors,
  hypertext: brand100,
};
